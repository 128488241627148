import $$ from 'dom7';
import Framework7 from 'framework7/framework7.esm.bundle.js';

// Import F7 Styles
// import 'framework7/css/framework7.bundle.css';
import 'framework7/css/framework7.bundle.rtl.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.scss';
// import '../../../components/css/fonts.css';

// Import Routes
import routes from './routes.js';

var app = new Framework7({
  root: '#app', // App root element

  name: 'midad', // App name
  theme: 'md', // Automatic theme detection

  material: true,
  //
  // // App root data
  // data: function () {
  //   return {
  //     user: {
  //       firstName: 'John',
  //       lastName: 'Doe',
  //     },
  //     // Demo products for Catalog section
  //     products: [
  //       {
  //         id: '1',
  //         title: 'Apple iPhone 8',
  //         description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi tempora similique reiciendis, error nesciunt vero, blanditiis pariatur dolor, minima sed sapiente rerum, dolorem corrupti hic modi praesentium unde saepe perspiciatis.'
  //       },
  //       {
  //         id: '2',
  //         title: 'Apple iPhone 8 Plus',
  //         description: 'Velit odit autem modi saepe ratione totam minus, aperiam, labore quia provident temporibus quasi est ut aliquid blanditiis beatae suscipit odio vel! Nostrum porro sunt sint eveniet maiores, dolorem itaque!'
  //       },
  //       {
  //         id: '3',
  //         title: 'Apple iPhone X',
  //         description: 'Expedita sequi perferendis quod illum pariatur aliquam, alias laboriosam! Vero blanditiis placeat, mollitia necessitatibus reprehenderit. Labore dolores amet quos, accusamus earum asperiores officiis assumenda optio architecto quia neque, quae eum.'
  //       },
  //     ]
  //   };
  // },
  // // App root methods
  // methods: {
  //   helloWorld: function () {
  //     app.dialog.alert('Hello World!');
  //   },
  // },
  // App routes
  // routes: routes,
  // clicks: {
  //   externalLinks: 'a',
  // },
  view: {
    router: false,
    // pushState: true,
    // pushStateSeparator: '',
  },
  navbar: {
    iosCenterTitle: true,
    mdCenterTitle: true,
  },
});

// Login Screen Demo
$$('#my-login-screen .login-button').on('click', function () {
  var username = $$('#my-login-screen [name="username"]').val();
  var password = $$('#my-login-screen [name="password"]').val();

  // Close login screen
  app.loginScreen.close('#my-login-screen');

  // Alert username and password
  app.dialog.alert('Username: ' + username + '<br>Password: ' + password);
});
//
// /*=== Popup ===*/
//
// $$('.pb-popup').on('click', function () {
//   console.log(222);
//
//     const myPhotoBrowserPopup = app.photoBrowser.create({
//         photos : [
//             'https://cdn.framework7.io/placeholder/sports-1024x1024-1.jpg',
//             'https://cdn.framework7.io/placeholder/sports-1024x1024-2.jpg',
//             'https://cdn.framework7.io/placeholder/sports-1024x1024-3.jpg',
//         ],
//         type: 'popup',
//         routableModals: false
//     });
//
//   myPhotoBrowserPopup.open();
// });

var swiper = app.swiper.create('.swiper-container', {
  slidesPerView: 'auto',
  spaceBetween: 10,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
});
