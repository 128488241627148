
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return { products: this.$root.products };
  },
  id: 'd358e53a59',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n  <div class="page" data-name="catalog">\n    <div class="navbar">\n      <div class="navbar-inner sliding">\n        <div class="title">Catalog</div>\n      </div>\n    </div>\n    <div class="page-content">\n      <div class="list links-list">\n        <ul>\n          ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.products, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n            <li><a href="/product/';
          r += c(ctx_2.id, ctx_2);
          r += '/">';
          r += c(ctx_2.title, ctx_2);
          r += '</a></li>\n          ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n        </ul>\n      </div>\n    </div>\n  </div>\n';
      return r;
    }(this);
  },
  styleScoped: false
};
    